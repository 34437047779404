(() => {
  const handleCardHeight = card => {
    // logic to add and remove a class based on client height of back and front sides
    const frontCard = card.querySelector('.flippable-card__front');
    const backCard = card.querySelector('.flippable-card__back');
    backCard.classList.remove('flippable-card__side--absolute');
    frontCard.classList.remove('flippable-card__side--absolute');

    if (backCard.clientHeight >= frontCard.clientHeight) {
      frontCard.classList.add('flippable-card__side--absolute');
    } else {
      backCard.classList.add('flippable-card__side--absolute');
    }

    const parentCarousel = card.closest('.aaaem-carousel');
    if (parentCarousel) {
      const carouselId = parentCarousel.getAttribute('id');
      const inst = window._tnsInstances?.[carouselId];
      inst?.updateSliderHeight?.();
      card.classList.add('flippable-card__wrapper--show');
    } else {
      card.classList.add('flippable-card__wrapper--show');
    }
  };

  const init = () => {
    const cards = document.querySelectorAll('.flippable-card__wrapper');

    if (cards?.length) {
      cards.forEach(card => {
        handleCardHeight(card);

        card.addEventListener('click', () => {
          card.classList?.toggle('flippable-card__wrapper--flipped');
        });

        // when the last image of each card is loaded, make sure that the card height is calculated again to be sure
        const imgs = card.querySelectorAll(
          'img'
        ) as NodeListOf<HTMLImageElement>;
        if (imgs?.length) {
          const lastImg = imgs[imgs.length - 1] as HTMLImageElement;
          if (lastImg.complete === true) {
            handleCardHeight(card);
          } else {
            lastImg.addEventListener('load', () => {
              handleCardHeight(card);
            });
          }
        }
      });

      window.addEventListener('resize', () => {
        cards.forEach(handleCardHeight);
      });
    }
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
