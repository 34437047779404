(() => {
  // adding a listener to the tabs
  // - to update the position of the pseudo element to the active trigger's place
  // - to update the corresponding select dropdown if there is one
  const addTabbyListener = () => {
    document.addEventListener(
      'tabby',
      event => {
        const curTab = event.target as HTMLElement;
        const curIndex = curTab?.getAttribute('data-index');
        const tabList = curTab?.closest('.aaaem-tabs__tablist') as HTMLElement;
        tabList.style.setProperty('--active-tab-position', '' + curIndex);

        // for a tab component that should show select in mobile,
        // the select dropdown will have a container with class custom-tabs__select-wrapper
        // the tabs and the custom-tabs__select-wrapper will be wrapped in another container with class custom-tabs
        // In this case, updating the value of the select dropdown when the tab is changed
        const closestTabSelect = curTab.closest('.custom-tabs');
        if (closestTabSelect) {
          const select = closestTabSelect.querySelector(
            '.custom-tabs__select-wrapper select'
          ) as HTMLSelectElement;
          if (select) {
            let selectedOptionsVal = select.value;
            Array.from(select.options).forEach((option, i) => {
              if (curIndex && i === parseInt(curIndex)) {
                option.selected = true;
              } else {
                option.selected = false;
              }
            });

            // guard to make sure that the event does not go into max call stack exceed error
            if (selectedOptionsVal !== select.value) {
              select.dispatchEvent(new Event('change'));
            }
          }
        }
      },
      false
    );
  };

  // adds initial data-index to tab trigger for future use
  // adds initial CSS variables to the tablist for the animation
  const addTabsData = allTabs => {
    allTabs.forEach(tabEl => {
      const tabTriggers = tabEl.querySelectorAll('.aaaem-tabs__tab a');
      const curTabList = tabEl.querySelector('.aaaem-tabs__tablist');
      curTabList.style.setProperty('--active-tab-position', '0');
      curTabList.style.setProperty(
        '--tab-width',
        '' + tabTriggers[0].clientWidth + 'px'
      );
      tabTriggers.forEach((trigger, index) => {
        trigger.setAttribute('data-index', '' + index);
      });
    });
  };

  // when resized, updates the tab's active animation pseudo element's width
  const addResizeListener = allTabs => {
    let winWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      const curWidth = window.innerWidth;
      if (curWidth !== winWidth) {
        winWidth = curWidth;

        allTabs.forEach(tabEl => {
          const curActiveTabTrigger = tabEl.querySelector(
            '.aaaem-tabs__tab a[aria-selected=true]'
          );
          const curTabList = tabEl.querySelector('.aaaem-tabs__tablist');
          curTabList.style.setProperty(
            '--tab-width',
            '' + curActiveTabTrigger.clientWidth + 'px'
          );
        });
      }
    });
  };

  // custom tabs component will have a dropdown in mobile and tab pills in desktop for the tab selection
  // this function keeps the tab pills in sync when the dropdown is changed
  // addTabbyListener keeps the dropdown in sync when the tab is changed
  const syncTabSelectDropdown = allTabs => {
    allTabs.forEach(tabsEl => {
      const closestTabSelect = tabsEl.closest('.custom-tabs');
      if (closestTabSelect) {
        const select = closestTabSelect.querySelector(
          '.custom-tabs__select-wrapper select'
        ) as HTMLSelectElement;
        const tabTriggers =
          closestTabSelect.querySelectorAll('.aaaem-tabs__tab a');
        select?.addEventListener('change', function () {
          const curValue = this.value;
          tabTriggers.forEach(trigger => {
            const tabVal = trigger.getAttribute('data-emu-item-title');

            // guard to make sure that the event does not go into max call stack exceed error
            if (
              tabVal === curValue &&
              trigger.getAttribute('aria-selected') !== true
            ) {
              trigger?.click?.();
            }
          });
        });
      }
    });
  };

  const init = () => {
    const allTabs = document.querySelectorAll('.aaaem-tabs');
    if (allTabs?.length) {
      addTabsData(allTabs);
      addTabbyListener();
      addResizeListener(allTabs);
      syncTabSelectDropdown(allTabs);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
