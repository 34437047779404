import { utils } from '../../utils';

const CONSTANTS = {
  articles: {
    cardClass: 'news__article',
    imageWrapper: 'news__article-poster-image',
    contentWrapper: 'news__article-content',
    text: 'news__article-title',
    tagWrapper: 'article-tags__wrapper',
    tag: 'article-tags__item',
  },
  pagination: {
    hidePaginationButtonClass: 'news__pagination-button--hidden',
    hidePageClass: 'news__page--hidden',
    activePageClass: 'news__page--active',
    dotsClass: 'news__page--dots',
    loaderClass: 'news__main--show-loader',
    disablePointerEvents: 'disable-pointer-events',
  },
};

// Defining the response structure
interface RESPONSE {
  articleLink: string;
  poster: string;
  tags: {
    [name: string]: string;
  };
  title: string;
  posterAltText: string;
}

interface RESPONSE_TEXT {
  result: [RESPONSE];
  totalItemCount: number;
}

// function to handle previous page and next page button
const handlePageButtons = (currentPage, allPages, pageWrapper) => {
  const totalPage = allPages.length - 1;

  // show previous and next page button if four or more pages
  if (totalPage > 3) {
    // If current page is 1 then hide previous button else show previous button
    if (currentPage !== 0) {
      const previousPageButton = pageWrapper.querySelector(
        '.news__pagination-button--prev.news__pagination-button--hidden'
      );
      if (previousPageButton) {
        previousPageButton.classList.remove(
          CONSTANTS.pagination.hidePaginationButtonClass
        );
      }
    } else {
      const previousPageButton = pageWrapper.querySelector(
        '.news__pagination-button--prev'
      );
      previousPageButton.classList.add(
        CONSTANTS.pagination.hidePaginationButtonClass
      );
    }

    // If current page is last page then hide next page button else show next page button
    if (currentPage !== totalPage) {
      const nextPageButton = pageWrapper.querySelector(
        '.news__pagination-button--next.news__pagination-button--hidden'
      );
      if (nextPageButton) {
        nextPageButton.classList.remove(
          CONSTANTS.pagination.hidePaginationButtonClass
        );
      }
    } else {
      const nextPageButton = pageWrapper.querySelector(
        '.news__pagination-button--next'
      );
      nextPageButton.classList.add(
        CONSTANTS.pagination.hidePaginationButtonClass
      );
    }
  }
};

// handle how pages will be displayed
// current page will always be displayed first
// if there are 4 pages all pages will be displayed
// if there are more than 4 pages and current page is not in last 4 then ... will be displayed for other pages
// example: if current page = 1 => 1 2 3 ... 10 >
//          if current page = 4 => < 4 5 6 ... 10 >
//          if current page = 7 => < 7 8 9 10 >
//          if current page = 10 => < 7 8 9 10

const pagination = (currentPage, allPages) => {
  const totalPage = allPages.length - 1;

  // if total pages are 4 then show all pages
  if (totalPage < 3) {
    if (currentPage === totalPage) {
      return allPages;
    }
  } else {
    // show dots if total pages are more than 4 and current page is not in last 4 pages
    if (currentPage < totalPage - 3) {
      allPages[currentPage + 2].classList.add(CONSTANTS.pagination.dotsClass);

      // logic to hide pages before current page and pages between current page + 2 and last page
      allPages.forEach((page, index) => {
        if (
          index < currentPage ||
          (index > currentPage + 2 && index != totalPage)
        ) {
          page.classList.add(CONSTANTS.pagination.hidePageClass);
        }
      });

      return allPages;
    } else {
      // hide all previous pages before current page
      allPages.forEach((page, index) => {
        if (index < totalPage - 3) {
          page.classList.add(CONSTANTS.pagination.hidePageClass);
        }
      });

      return allPages;
    }
  }
};

(() => {
  const parseResponseToCard = (response: RESPONSE): HTMLElement | null => {
    const fragment = new DocumentFragment();

    // creating div for article card
    const card = document.createElement('div');
    card.setAttribute('class', CONSTANTS.articles.cardClass);
    fragment.appendChild(card);

    const imageAnchor = document.createElement('a');
    imageAnchor.setAttribute('href', response.articleLink);
    imageAnchor.setAttribute('class', CONSTANTS.articles.imageWrapper);
    card.appendChild(imageAnchor);

    // adding article poster image
    const image = document.createElement('img');
    image.setAttribute('src', response.poster);
    image.setAttribute('alt', response.posterAltText);
    imageAnchor.appendChild(image);

    const contentWrapper = document.createElement('div');
    contentWrapper.setAttribute('class', CONSTANTS.articles.contentWrapper);
    card.appendChild(contentWrapper);

    const textWrapper = document.createElement('div');
    textWrapper.setAttribute('class', CONSTANTS.articles.text);
    contentWrapper.appendChild(textWrapper);

    const textAnchor = document.createElement('a');
    textAnchor.setAttribute('href', response.articleLink);
    textWrapper.appendChild(textAnchor);

    // adding article title
    const heading = document.createElement('h4');
    heading.innerHTML = response.title;
    textAnchor.appendChild(heading);

    // adding article tags with colors
    const tagWrapper = document.createElement('div');
    tagWrapper.classList.add(CONSTANTS.articles.tagWrapper);
    Object.values(response.tags).forEach(tag => {
      const tagLink = document.createElement('span');
      tagLink.setAttribute('class', CONSTANTS.articles.tag);
      tagLink.innerText = tag;
      tagWrapper.appendChild(tagLink);
    });
    utils.handleTag(tagWrapper);
    contentWrapper.appendChild(tagWrapper);

    return card;
  };

  const handlePagination = news => {
    const pageWrapper = news.querySelector('.news__pagination');
    const pageContainer = news.querySelector('.news__pagination > ul');
    const allPagesList = pageContainer.querySelectorAll('li');
    let currentPage = 1;
    const activePage = pageContainer.querySelector('li');

    // highlight active page
    activePage.classList.add(CONSTANTS.pagination.activePageClass);

    const pageLinkPrefix = news
      .querySelector('.news__pagination')
      .getAttribute('data-page-link');

    const searchKeyword = news?.querySelector(
      '.news__title-search-keyword'
    )?.innerText;

    let searchLinkPrefix = news
      .querySelector('.news__pagination')
      .getAttribute('data-search-page-link');

    if (searchLinkPrefix) {
      searchLinkPrefix = searchLinkPrefix + `query=${searchKeyword}&`;
    }

    const tagLinkPrefix = news
      .querySelector('.news__pagination')
      .getAttribute('data-tag-page-link');

    const linkPrefix = searchLinkPrefix || tagLinkPrefix || pageLinkPrefix;

    const cardWrapper = news.querySelector('.news__main');
    const allPages = pageContainer.querySelectorAll('li');
    const prevButton = pageWrapper.querySelector(
      '.news__pagination-button--prev'
    );
    const nextButton = pageWrapper.querySelector(
      '.news__pagination-button--next'
    );

    const handlePageClick = pageNumber => {
      currentPage = pageNumber;
      const link = linkPrefix + `page=${pageNumber}`;
      const allHiddenPages = pageContainer.querySelectorAll(
        '.news__page--hidden'
      );
      const dot = pageContainer.querySelector('.news__page--dots');

      // highlight active page
      const previousPage = pageContainer.querySelector('.news__page--active');
      previousPage.classList.remove(CONSTANTS.pagination.activePageClass);

      let activePageNumber;

      for (let i = 0; i < allPagesList.length; i++) {
        if (parseInt(allPagesList[i].innerText) === currentPage) {
          activePageNumber = allPagesList[i];
        }
      }

      if (activePageNumber) {
        activePageNumber.classList.add(CONSTANTS.pagination.activePageClass);
      }

      // remove hidden pages
      if (allHiddenPages) {
        allHiddenPages.forEach(page => {
          page.classList.remove(CONSTANTS.pagination.hidePageClass);
        });
      }

      // remove dot
      if (dot) {
        dot.classList.remove(CONSTANTS.pagination.dotsClass);
      }

      // handle page buttons and pagination
      handlePageButtons(currentPage - 1, allPagesList, pageWrapper);
      pagination(currentPage - 1, allPagesList);

      cardWrapper.classList.add(CONSTANTS.pagination.loaderClass);
      cardWrapper.classList.add(CONSTANTS.pagination.disablePointerEvents);
      pageWrapper.classList.add(CONSTANTS.pagination.disablePointerEvents);

      // ajax call for cards on current page
      utils.ajaxGetCall(link, responseText => {
        const responseObj = responseText as RESPONSE_TEXT;
        let firstCard;
        const docFrag = document.createDocumentFragment();

        // Parse the response to card and append in the container
        responseObj.result.forEach(response => {
          const card = parseResponseToCard(response as RESPONSE);
          firstCard = firstCard || card;

          if (!card) return;
          docFrag.appendChild(card);
        });

        if (docFrag?.children?.length) {
          cardWrapper.replaceChildren(docFrag);
        }

        cardWrapper.classList.remove(CONSTANTS.pagination.loaderClass);
        cardWrapper.classList.remove(CONSTANTS.pagination.disablePointerEvents);
        pageWrapper.classList.remove(CONSTANTS.pagination.disablePointerEvents);

        //  scrolling the page to make the first of appended cards appear on top of the screen
        if (firstCard) {
          news?.scrollIntoView?.();
        }
      });
    };

    const appendListeners = () => {
      // listener for previous page arrow button
      prevButton?.addEventListener('click', () => {
        handlePageClick(currentPage - 1);
      });

      // listener for next page arrow button
      nextButton?.addEventListener('click', () => {
        handlePageClick(currentPage + 1);
      });

      // listener for page number click
      pageContainer?.addEventListener('click', ev => {
        const pageNumber = parseInt(ev.target.innerText);
        if (pageNumber && pageNumber != currentPage) {
          handlePageClick(pageNumber);
        }
        ev.preventDefault();
      });
    };

    // append all event listeners
    appendListeners();

    // handle previous and next page buttons
    handlePageButtons(currentPage - 1, allPagesList, pageWrapper);

    // handle pagination
    pagination(currentPage - 1, allPages);
  };

  const init = () => {
    const tagsContainer = document.querySelectorAll('.article-tags__wrapper');

    if (tagsContainer?.length) {
      tagsContainer.forEach(tagContainer => {
        utils.handleTag(tagContainer);
      });
    }

    const allNews = document.querySelectorAll('.news');

    allNews?.forEach(news => {
      const newsWithPagination = news.querySelector('.news__pagination');
      if (newsWithPagination) {
        if (!news.classList.contains('news__processed')) {
          news.classList.add('news__processed');
          handlePagination(news);
        }
      }
    });

    const titleTag = document.querySelector('.news__title-tag-keyword');
    if (titleTag) {
      utils.handleSpecialSymbol(titleTag);
    }
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
