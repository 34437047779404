export const utils = {
  // Randomly add font color and background color to tags such that no next tag has same color as of previous tag
  // Add HTML <sup> tag to any special symbol present in tag
  handleTag(tagContainer) {
    const tags = tagContainer.querySelectorAll('span');
    let colorArray = ['gray', 'purple', 'gold'];

    // for currently selected color
    let currentValue = '';

    tags.forEach(tag => {
      // Add <sup> </sup> tag to special symbol
      this.handleSpecialSymbol(tag);

      // Selected color should not be same as previous color
      let newColorArray = colorArray.filter(c => c !== currentValue);
      let index = Math.floor(Math.random() * newColorArray.length);
      let randomNumber = newColorArray[index];
      currentValue = randomNumber;
      tag.classList.add(`article-tags__item--${randomNumber}`);
    });
  },

  handleSpecialSymbol(tag) {
    const specialSymbolArray = ['®', '©'];
    specialSymbolArray?.forEach(symbol => {
      if (tag?.innerHTML?.includes(symbol)) {
        const newText = tag.innerHTML.replaceAll(
          symbol,
          `<sup>${symbol}</sup>`
        );
        tag.innerHTML = newText;
      }
    });
  },

  //Ajax get call
  ajaxGetCall: (url: string, onSuccess: any) => {
    fetch(url)
      .then(res => res.json())
      .then(res => onSuccess(res))
      .catch(err => console.error(err));
  },
};
