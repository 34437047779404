// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './components/flippable-card/flippable-card';
import './components/image-map/image-map';
import './components/tabs/tabs';
import './components/swiper/swiper';
import './components/injectionspoint/injectionspoint';
import './components/news/news';
import './components/share/share';

import initializeCustomSelect from './components/custom-select/custom-select';

// import favicon here.

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
    _swiperCarouselInstances: any; // swiper carousels
    _ssSelectInstance: any; // Slim Select (Custom Select) Instances
    ContextHub: any;
  }
}

const CONSTANTS = {
  header: {
    mobileNavigation: {
      closeIconId: 'header__menu-mobile-close',
      menuTriggerId: 'header__menu-mobile-trigger',
    },
    stickyClassName: 'header--sticky',
    eyebrowBrands: {
      triggerId: 'aesthetics-brands-trigger',
      closeId: 'aesthetics-brands-close',
    },
  },
};

// This function will recreate custom table for mobile devices
const setTableForMobile = function () {
  const tableEl = document.querySelectorAll('table');
  if (tableEl.length) {
    tableEl.forEach(item => {
      const thEls = item.querySelectorAll('thead th') as NodeListOf<Element>;
      const tdLabels: string[] = [];

      Array.from(thEls).forEach(el => {
        if (el instanceof HTMLElement) {
          tdLabels.push(el.innerHTML);
        }
      });

      item.querySelectorAll('tbody tr').forEach(tr => {
        Array.from(tr.children).forEach((td, ndx) => {
          td.innerHTML = `<span class="title">${tdLabels[ndx]}</span><span class="content">${td.innerHTML}</span>`;
          // td.setAttribute('label', tdLabels[ndx]);
        });
      });
    });
  }
};

(() => {
  // This function will help to close the opened menu on user interaction
  const menuCloseHandler = (closeIconId: string, menuTriggerId: string) => {
    const menuTrigger = document.querySelector(
      '#' + menuTriggerId
    )! as HTMLElement;
    const closeIconBtn = document.querySelector(
      '#' + closeIconId
    )! as HTMLElement;

    closeIconBtn.addEventListener('click', e => {
      e.preventDefault();
      menuTrigger?.click();
    });
  };

  // closes patient safety, allergan brand and mobile menu navigation
  const closeMenuHandler = () => {
    // Close mobile navigation
    menuCloseHandler(
      CONSTANTS.header.mobileNavigation.closeIconId,
      CONSTANTS.header.mobileNavigation.menuTriggerId
    );

    //Close Allergan Aesthetics Brands
    menuCloseHandler(
      CONSTANTS.header.eyebrowBrands.closeId,
      CONSTANTS.header.eyebrowBrands.triggerId
    );
  };

  // manages the sticky nature of the header
  const handleScrollMenu = () => {
    const header = document.querySelector('header.header') as HTMLElement;
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header.classList.add(CONSTANTS.header.stickyClassName);
      } else {
        header.classList.remove(CONSTANTS.header.stickyClassName);
      }
    });
  };

  // The carousel needs animation for the indicators. This function adds the animation functionality for all the carousels
  // Add a class 'aaaem-carousel--static-indicators' to the carousel to avoid adding the animation
  const setCarouselAttributes = () => {
    if (window._tnsInstances) {
      const values = Object.values(window._tnsInstances);
      if (values?.length) {
        values.forEach(inst => {
          // @ts-ignore;
          const carouselInfo = inst?.getInfo?.();
          if (carouselInfo) {
            try {
              const container = carouselInfo.container;
              const carouselWrapper = container?.closest?.('.aaaem-carousel');

              // skip the animation if the indicators animation is not needed
              if (
                carouselWrapper.classList?.contains(
                  'aaaem-carousel--static-indicators'
                )
              ) {
                return;
              }

              // skip the animation if the indicators are thumbnails
              if (
                carouselWrapper.getAttribute?.('data-nav-as-thumbnails') ===
                'true'
              ) {
                carouselWrapper?.classList?.add(
                  'aaaem-carousel--static-indicators'
                );
                return;
              }

              // @ts-ignore;
              const carouselIndicators = carouselInfo.navContainer;
              if (carouselIndicators instanceof HTMLElement) {
                carouselWrapper?.classList?.add('aaaem-carousel--show');
                const indicatorWidth = 100 / carouselInfo.slideCount;
                carouselIndicators.style.setProperty(
                  '--width',
                  indicatorWidth + '%'
                );
                // @ts-ignore;
                inst.events.on('transitionStart', e => {
                  const indicatorPos = e.navCurrentIndex * indicatorWidth;
                  carouselIndicators.style.setProperty(
                    '--left',
                    indicatorPos + '%'
                  );
                });
              } else {
                carouselWrapper?.classList?.add(
                  'aaaem-carousel--static-indicators'
                );
              }
            } catch {
              const container = carouselInfo?.container;
              const carouselWrapper = container?.closest?.('.aaaem-carousel');
              carouselWrapper?.classList?.add(
                'aaaem-carousel--static-indicators'
              );
            }
          }
        });
      }
    }
  };

  // on home carousel if video is being played, and the carousel slide is changed, pause the video
  const handleHomeTestimonialCarousel = () => {
    const carouselInst = window._tnsInstances?.['testimonial-carousel'];
    if (carouselInst) {
      carouselInst.events.on('transitionStart', e => {
        const prevSlide = e?.slideItems?.[e?.indexCached];
        const prevEmbedId = prevSlide?.querySelector('.emu-video-plyr')?.id;
        stopPlyrVideo(prevEmbedId);

        // clicking the play button present in the active carousel item
        // This could have been done with player instance,
        // But since this is inside the looped carousel, embed instance is getting overridden because carousel clones slide items,
        // Playing one embed is actually triggering the play on a cloned item. So, relying on clicking play button instead.
        const currentSlide = e?.slideItems?.[e?.index];
        const curEmbedPlayBtn = currentSlide?.querySelector(
          '.plyr__control.plyr__control--overlaid'
        );
        curEmbedPlayBtn?.click?.();
      });
    }
  };

  // in the results page this function will scroll to the top of the patients list when show less button is clicked
  const scrollResultsPatientCards = () => {
    const patientCardWrapper = document.querySelector(
      '.results-card__wrapper'
    ) as HTMLElement;
    if (patientCardWrapper) {
      window.Bus?.on('emu-button:click', e => {
        if (e.id === 'see-more-patients' && e.toggleOn === false) {
          patientCardWrapper.scrollIntoView();
        }
      });
    }
  };

  // Opens modal in results page when patient grid item is clicked in results page
  const showResultsModal = () => {
    const results = document.querySelectorAll(
      '.results-card__grid .results-card__item'
    );
    const showModalBtn = document.querySelector(
      '.show-modal-btn'
    ) as HTMLElement;
    const resultsCarouselInst =
      window._tnsInstances?.['carousel-result-slider'];
    const triggerCarouselInst =
      window._tnsInstances?.['carousel-result-trigger-slider'];
    if (resultsCarouselInst && results?.length) {
      results.forEach((item, index) => {
        item.addEventListener('click', e => {
          e.preventDefault();
          showModalBtn?.click();
          resultsCarouselInst?.goTo?.(index);
        });
      });

      window.Bus.on('emu-modal:open', ({ id }) => {
        if (id === 'results-modal') {
          // making sure that the modal settles in
          setTimeout(() => {
            resultsCarouselInst?.updateSliderHeight?.();
            triggerCarouselInst?.updateSliderHeight?.();
          }, 50);
        }
      });
    }
  };

  // adds class to the parent of cards that has card--show to show the cards by default when the page is loaded on mobile
  // on mobile show first 6 cards, and clicking on show more shows the rest of the cards
  // toggling of js-toggle-on class is handled by aaaem-common, based on which the cards will be shown via css, with help of card-container--show class
  const handleResultsMobileCards = () => {
    const cardDiv = document.querySelectorAll(
      '.card-main-wrapper .card-wrapper .card'
    );
    if (cardDiv) {
      cardDiv.forEach(item => {
        if (item.classList.contains('card--show')) {
          item.parentElement?.classList.add('card-container--show');
        }
      });
    }
  };

  // stops plyr video for a give id
  const stopPlyrVideo = id => {
    const prevEmbedPlyrInstance = window._plyrInstances?.[id];
    if (prevEmbedPlyrInstance?.playing) {
      prevEmbedPlyrInstance?.stop?.();
    }
  };

  // In Results page, keeps the results carousel inside the modal, in sync with the results trigger thumbnail carousel
  // adds click events to the carousel trigger slider element
  // makes sure that the videos are not playing when slide is changed, or when the modal is closed
  const handleResultsModal = () => {
    const resultTriggerCarousel = document.getElementById(
      'results-modal-trigger-carousel'
    );
    const resultsInst = window._tnsInstances?.['carousel-result-slider'];
    const resultsTriggerInst =
      window._tnsInstances?.['carousel-result-trigger-slider'];
    if (resultsInst && resultsTriggerInst) {
      const triggerSliderInfo = resultsTriggerInst.getInfo();
      const slideItems =
        triggerSliderInfo?.slideItems as NodeListOf<HTMLElement>;
      const slideItemsArr = Array.from(slideItems);
      const moreVideosCta = document.querySelector(
        '.results__ba-more-videos-cta'
      ) as HTMLButtonElement;
      const modal = document.querySelector('.results__ba') as HTMLElement;

      // closes the more video overlay if it is visible
      const closeMoreVideos = () => {
        if (moreVideosCta?.classList?.contains('js-toggle-on')) {
          moreVideosCta?.click();
        }
      };

      // when clicked anywhere outside the thumbnail overlay, making sure that the overlay is closed.
      window.Bus.on('emu-button:click', e => {
        if (e.id === 'ba-more-videos-cta') {
          if (e.toggleOn) {
            modal.addEventListener('click', closeMoreVideos);
            document.body.addEventListener('click', closeMoreVideos);
          } else {
            modal.removeEventListener('click', closeMoreVideos);
            document.body.removeEventListener('click', closeMoreVideos);
          }
        }
      });

      let screenX = 0;
      let screenY = 0;

      // moving the results trigger carousel, when results carousel changed its state
      // also changing the class of the results trigger carousel active item accordingly
      // pausing any videos that are playing
      resultsInst.events.on('indexChanged', e => {
        const { index } = e;
        resultsTriggerInst.goTo(index);

        slideItemsArr.forEach((e, i) => {
          if (i === index) {
            e.classList.add('active-slide');
          } else {
            e.classList.remove('active-slide');
          }
        });

        const prevSlide = e?.slideItems?.[e?.indexCached];
        const prevEmbedId = prevSlide?.querySelector('.emu-video-plyr')?.id;

        if (prevEmbedId) {
          stopPlyrVideo(prevEmbedId);
        }
      });

      // when the modal is closed, making sure that the video is stopped and overlay is closed.
      window.Bus.on('emu-modal:close', ({ id }) => {
        const resultInstInfo = resultsInst?.getInfo();
        if (id === 'results-modal' && resultInstInfo) {
          const curSlide = resultInstInfo?.slideItems?.[resultInstInfo.index];
          const curEmbedId = curSlide?.querySelector('.emu-video-plyr')?.id;
          stopPlyrVideo(curEmbedId);

          document.body.removeEventListener('click', closeMoreVideos);
          modal.removeEventListener('click', closeMoreVideos);
          if (moreVideosCta?.classList?.contains('js-toggle-on')) {
            moreVideosCta?.click();
          }
        }
      });

      // when items inside the results trigger carousel are clicked, changing the state of results carousel accordingly
      slideItemsArr.forEach((el, i) => {
        el.addEventListener('click', e => {
          if (screenX === e.screenX && screenY === e.screenY) {
            moreVideosCta?.click();
            resultsInst.goTo(i);
          }
        });
      });

      resultTriggerCarousel?.addEventListener('mousedown', e => {
        screenX = e.screenX;
        screenY = e.screenY;
      });
    }
  };

  // initializing the custom select dropdown for all select elements with parent custom-select class
  const initCustomSelect = () => {
    const selects: NodeListOf<HTMLSelectElement> = document.querySelectorAll(
      '.custom-select select'
    );

    if (selects?.length) {
      selects.forEach((select: HTMLSelectElement) => {
        initializeCustomSelect?.(select, {
          showSearch: false,
          contentLocation: select.closest('.custom-select'),
        });
      });
    }
  };

  // This function manages options on the practice support page
  // on clicking form select dropdown, it will show/hide respective query content
  const handleStepQueries = () => {
    const formSelect = document.querySelector(
      '.practice-support__steps-main select'
    );

    const contentList = document.querySelectorAll(
      '.practice-support__steps-query-content'
    );

    if (contentList?.length && formSelect) {
      // adding show class to the first available content option
      contentList[0].classList.add(
        'practice-support__steps-query-content--show'
      );

      // when the dropdown value is changed, changing the visibility of the corresponding section
      formSelect.addEventListener('change', e => {
        if (e.target) {
          const selectedVal = e.target as HTMLButtonElement;
          if (selectedVal.value) {
            contentList.forEach(item => {
              const contentId = item.id;
              if (contentId === selectedVal.value) {
                item.classList.add(
                  'practice-support__steps-query-content--show'
                );
              } else {
                item.classList.remove(
                  'practice-support__steps-query-content--show'
                );
              }
            });
          }
        }
      });
    }
  };

  const getDeviceDetails = () => {
    if (navigator.userAgent.indexOf('iPhone') >= 0) {
      document.body.classList.add('is-iphone');
    }
  };

  // This function manages the back button on multiple pages.
  const handleBackButton = () => {
    const backButton = document.querySelectorAll(
      '.article-details__button--back-button'
    );

    // When the back button is clicked , checks for previous page and redirects to it.
    if (backButton) {
      backButton.forEach(item => {
        item.addEventListener('click', e => {
          if (history.length > 1) {
            e.preventDefault();
            history.back();
          }
        });
      });
    }
  };

  // This function manages the header on back button history pages.
  const handleHeaderOnBackButton = () => {
    // This prevents the page from scrolling down to where it was previously.
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded.
    window.scrollTo(0, 0);
  };

  const adjustCarouselDimensions = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      let winWidth = window.innerWidth;

      // once the window load event happened, setting the carousel height
      window.addEventListener('load', () => {
        carouseInstances.forEach(inst => {
          // @ts-ignore
          inst?.updateSliderHeight?.();
        });
      });

      const toggleCarouselDisabledClass = () => {
        carouseInstances.forEach(inst => {
          // @ts-ignore
          const info = inst?.getInfo?.();
          if (info && info.container) {
            const carouselWidth = info.container.clientWidth;
            const mainCarousel = info.container.closest('.aaaem-carousel');
            // @ts-ignore
            inst?.updateSliderHeight?.();
            mainCarousel?.classList?.toggle(
              'aaaem-carousel--disabled',
              carouselWidth <= winWidth
            );
          }
        });
      };
      toggleCarouselDisabledClass();

      // if the carousel's content width is
      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          toggleCarouselDisabledClass();
        }
      });
    }
  };

  // shows the recaptcha error only if the form is touched.
  const hideCaptchaError = captcha => {
    const form = captcha?.closest('form');
    if (form) {
      form.addEventListener('submit', () => {
        form.classList.add('form-touched');
      });
    }
  };

  // checks if the captcha is initiated, and initiates it if it is not already initiated.
  const checkAndInitCaptcha = () => {
    const captcha = document.querySelector('[data-component="recaptcha"]');
    if (captcha) {
      const recaptchaAdded = captcha?.querySelector('.recaptcha-container div');
      if (recaptchaAdded) {
        hideCaptchaError(captcha);
      } else {
        window.Bus.emit('emu-form-recaptcha:load');
        // intentionally commented. This may be needed later if the load event above does not work on dev env
        // dispatchEvent(new Event('load'));
        hideCaptchaError(captcha);
      }
    }
  };

  const handlePageEvents = () => {
    initCustomSelect();
    setTableForMobile();
    closeMenuHandler();
    handleScrollMenu();
    setCarouselAttributes();
    getDeviceDetails();
    handleBackButton();
    handleHeaderOnBackButton();
    adjustCarouselDimensions();
    checkAndInitCaptcha();

    // Home page related functionalities
    const isHome = document.querySelector('#home');
    if (isHome) {
      handleHomeTestimonialCarousel();
    }

    // Results page related functionalities
    const isResults = document.querySelector('#results');
    if (isResults) {
      scrollResultsPatientCards();
      showResultsModal();
      handleResultsMobileCards();
      handleResultsModal();
    }

    // Practice Support page related functionalities
    const isPracticeSupport = document.querySelector('#practice-support');
    if (isPracticeSupport) {
      handleStepQueries();
    }
  };

  // listens to the context hub ready event and triggers page load events
  const listenToContextHubLoadedEvent = () => {
    const contextHub = window.ContextHub;
    if (contextHub) {
      window.ContextHub?.eventing?.on(
        window.ContextHub.Constants.EVENT_TEASER_LOADED,
        () => {
          handlePageEvents();
        }
      );
    } else {
      handlePageEvents();
    }
  };

  const init = () => {
    // if the page has target node form context hub, wait fot he context hub to load and init the page. Otherwise, initialize the page immediately
    const contextHubNode = document.querySelector(
      '[id^="_content_botox-cosmetic-hcp_en"]'
    );
    if (contextHubNode) {
      try {
        listenToContextHubLoadedEvent();
      } catch (e) {
        console.warn(e);
        handlePageEvents();
      }
    } else {
      handlePageEvents();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
