import Swiper from 'swiper';
import 'swiper/swiper.scss';

(() => {
  const initSwiperCarousels = () => {
    // initiating swiper carousel for all elements that has class `swiper-carousel`
    // Here is the structure to be followed.
    // 1. Author a container component, and add the class `swiper-carousel`. Add optional class swiper-carousel--disabled-in-mobile to disable the carousel in mobile view
    // 2. Author another container component inside `swiper-carousel` container, and add the class `swiper-wrapper`
    // 3. Author containers inside `swiper-wrapper` which will work ask carousel slides
    // 4. Author slide content inside the slide containers authored in #3
    // Instance of the swiper carousel is available in window variable window._swiperCarouselInstances

    // Disabling in author
    const isAuthor = document.querySelector('.cq-Editable-dom--container');
    if (isAuthor) return;

    const swiperCarousels = document.querySelectorAll('.swiper-carousel');
    if (swiperCarousels.length) {
      swiperCarousels.forEach(swiperCarousel => {
        const swiperCarouselId = swiperCarousel.id;
        const container = swiperCarousel.querySelector('.container');
        const swiperOuterWrapper = swiperCarousel.closest(
          '.swiper-carousel__outer-wrapper'
        ) as HTMLElement;
        const moreBtn = swiperOuterWrapper?.querySelector(
          '.swiper-carousel__more-btn'
        ) as HTMLButtonElement;
        container?.classList.add('swiper', `${swiperCarouselId}-swiper`);
        const swiperWrapper = swiperCarousel.querySelector('.swiper-wrapper');
        const slides = swiperWrapper?.children;

        if (slides) {
          Array.from(slides).forEach(slide => {
            slide.classList.add('swiper-slide');
          });
          const disabledInMobile = swiperCarousel.classList.contains(
            'swiper-carousel--disabled-in-mobile'
          );
          let winWidth = window.innerWidth;

          window._swiperCarouselInstances =
            window._swiperCarouselInstances || {};

          const addMoreBtnEvents = () => {
            moreBtn?.addEventListener('click', () => {
              const inst = window._swiperCarouselInstances[swiperCarouselId];
              if (inst) {
                const newActiveSlide = inst?.activeIndex + 1;
                if (!inst?.isEnd) {
                  inst?.slideTo?.(newActiveSlide);
                }
              }
            });
          };

          // add class to the swiper-carousel__outer-wrapper when carousel reaches the end of the slides
          const appendCarouselEvents = carouselInst => {
            swiperOuterWrapper?.classList?.toggle(
              'swiper-carousel__outer-wrapper--reached-end',
              carouselInst?.isEnd
            );

            carouselInst?.on('slideChange', () => {
              swiperOuterWrapper?.classList.toggle(
                'swiper-carousel__outer-wrapper--reached-end',
                carouselInst?.isEnd
              );
            });

            carouselInst?.on('reachEnd', () => {
              swiperOuterWrapper?.classList.toggle(
                'swiper-carousel__outer-wrapper--reached-end',
                carouselInst?.isEnd
              );
            });

            carouselInst?.on('fromEdge', () => {
              swiperOuterWrapper?.classList.toggle(
                'swiper-carousel__outer-wrapper--reached-end',
                carouselInst?.progress < 0.9 && carouselInst?.progress > 0.1
              );
            });
          };

          // initiates the carousel
          const initiateCarousel = () => {
            window._swiperCarouselInstances[swiperCarouselId] = new Swiper(
              `.${swiperCarouselId}-swiper`,
              {
                spaceBetween: 22,
                slidesPerView: 'auto',
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20,
                longSwipesRatio: 0,
                breakpoints: {
                  1280: {
                    slidesOffsetAfter: 80,
                    slidesOffsetBefore: 80,
                  },
                  1440: {
                    slidesOffsetBefore: window.innerWidth / 2 - 1440 / 2 + 80,
                  },
                },
              }
            );

            if (moreBtn) {
              addMoreBtnEvents();
            }
          };

          // checks whether the width of the carousel is less than the width of the screen
          // since there is no specific method available, looping through the slides and counting the carousel width
          const isCarouselSmallerThanScreen = () => {
            const inst = window._swiperCarouselInstances[swiperCarouselId];
            if (inst?.slides?.length) {
              const totalSlidesWidth = inst?.slides
                ?.map(slide => {
                  return slide.clientWidth + inst.params.spaceBetween;
                })
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                );
              return totalSlidesWidth < window.innerWidth;
            }
            return false;
          };

          // checks and initiates the carousel
          const checkAndInitCarousel = () => {
            const inst = window._swiperCarouselInstances[swiperCarouselId];
            // destroy the carousel if carousel width is less than screen width, or if screen size is less than 1024 and if disabledInMobile class is added
            const canDestroyCarousel =
              (window.innerWidth < 1024 && disabledInMobile) ||
              (inst && isCarouselSmallerThanScreen());
            if (canDestroyCarousel && inst) {
              swiperOuterWrapper?.classList.add(
                'swiper-carousel__outer-wrapper--disabled'
              );
              inst.destroy();
            } else {
              initiateCarousel();
              const newCarouselInst =
                window._swiperCarouselInstances[swiperCarouselId];
              appendCarouselEvents(newCarouselInst);

              swiperOuterWrapper?.classList.remove(
                'swiper-carousel__outer-wrapper--disabled'
              );

              // check and destroy the carousel again after initiating the carousel
              if (
                (window.innerWidth < 1024 && disabledInMobile) ||
                isCarouselSmallerThanScreen()
              ) {
                newCarouselInst.destroy();
                swiperOuterWrapper?.classList.add(
                  'swiper-carousel__outer-wrapper--disabled'
                );
              }
            }
          };

          // when window is resized, reinit the carousel
          window.addEventListener('resize', () => {
            const curWidth = window.innerWidth;
            if (curWidth !== winWidth) {
              winWidth = curWidth;
              checkAndInitCarousel();
            }
          });

          checkAndInitCarousel();
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initSwiperCarousels);
  } else {
    initSwiperCarousels();
  }
})();
