import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.css';

window._ssSelectInstance = window._ssSelectInstance || {};

const initializeCustomSelect = (selector, settings) => {
  let select;
  if (typeof selector === 'string') {
    select = document.querySelector(selector);
  } else if (selector instanceof HTMLElement) {
    select = selector;
  }

  if (select) {
    select.classList.add('custom-select__main');
    let id = select.getAttribute?.('id');
    if (!id) {
      id = `select-${Math.round(Math.random() * 999999999)}`;
      select.setAttribute?.('id', id);
    }

    window._ssSelectInstance[id] = new SlimSelect({
      select,
      settings,
    });
  }
};

export default initializeCustomSelect;
