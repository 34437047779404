(() => {
  const handleLinkClick = (linkTag: HTMLAnchorElement) => {
    let copylink = linkTag.href;

    // functionality for the share link in article detail component to copy the url to clipboard.
    const copyToClipboard = async link => {
      await navigator.clipboard.writeText(link);
    };

    linkTag.addEventListener('click', e => {
      e.preventDefault();
      copyToClipboard(copylink);
    });
  };

  // This function wil combine data-prefix and href for social media share icon on article detail page
  const handleShareMedia = () => {
    const socialMediaLinks = document.querySelectorAll(
      '.article-details__share-container > .share ul li a.share__media'
    );
    if (socialMediaLinks?.length) {
      socialMediaLinks.forEach(socialMediaItem => {
        const dataPrefix = socialMediaItem.getAttribute('data-prefix');

        if (socialMediaItem instanceof HTMLAnchorElement) {
          const datahref = socialMediaItem.href;
          if (dataPrefix && datahref) {
            socialMediaItem.setAttribute('href', dataPrefix + datahref);
          }
        }
      });
    }
  };

  const init = () => {
    const linksTag = document.querySelectorAll(
      '.share__media--copy'
    ) as NodeListOf<HTMLElement>;

    if (linksTag?.length) {
      linksTag.forEach(linkTag => {
        handleLinkClick(linkTag);
      });
    }
    handleShareMedia();
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
