(() => {
  const handleInjectionPoints = (injectionPoint: HTMLElement) => {
    const linesSections = injectionPoint?.querySelectorAll(
      '.injectionpoints__line-section'
    );
    const linesDropdown = injectionPoint?.querySelector(
      '.injectionpoints__select--line-type'
    ) as HTMLSelectElement;
    const patternDropdowns = injectionPoint?.querySelectorAll(
      '.injectionpoints__select--line-location'
    ) as NodeListOf<HTMLSelectElement>;
    const ctaWrapper = injectionPoint?.querySelector(
      '.injectionpoints__button-wrapper'
    ) as HTMLElement;
    let selectedCTAType;
    let selectedLineType;
    let selectedLineLocation;

    // returns the current active section
    const getActiveSection = () =>
      injectionPoint?.querySelector(
        `.injectionpoints__line-section[data-line-type="${selectedLineType}"]`
      );

    // hides all the line content sections and shows the active line content section
    const showActiveSection = () => {
      const activeLineSection = injectionPoint?.querySelector(
        `.injectionpoints__line-section[data-line-type="${selectedLineType}"]`
      );
      linesSections.forEach(section => {
        section.classList.remove('show');
      });
      activeLineSection?.classList.add('show');
    };

    // hides all the images and shows the active image
    const showActiveImage = () => {
      const activeSection = getActiveSection()! as HTMLElement;
      const allImages = activeSection?.querySelectorAll(
        '.injectionpoints__image'
      )!;
      const activeImage = activeSection?.querySelector(
        `.injectionpoints__image[data-line-type="${selectedLineType}"][data-line-location="${selectedLineLocation}"][data-type="${selectedCTAType}"]`
      )!;

      allImages?.forEach(img => {
        img.classList.remove('show');
      });
      activeImage?.classList.add('show');
    };

    // hides all the location dropdowns and shows the active location dropdown
    // also selects the first non disabled option of the location data
    const showActiveLocationDropdown = () => {
      const activeLocationDropdown = injectionPoint?.querySelector(
        `.injectionpoints__select[data-line-type="${selectedLineType}"]`
      )! as HTMLSelectElement;

      patternDropdowns?.forEach(patternDropdown => {
        patternDropdown
          .closest('.injectionpoints__select-inner')
          ?.classList.remove('show');
      });
      activeLocationDropdown
        .closest('.injectionpoints__select-inner')
        ?.classList.add('show');
    };

    // shows active sections, active location dropdown and active image
    const showData = () => {
      showActiveSection();
      showActiveLocationDropdown();
      showActiveImage();
    };

    // appending listeners to the dropdowns (lines and locations)
    const appendDropdownListeners = () => {
      // lines dropdown listener
      // when changed, select the first value of patterns dropdown, set the value of selectedLineType
      linesDropdown?.addEventListener('change', function () {
        selectedLineType = this.value || linesDropdown.options[0].value;

        // selecting the first option in the pattern dropdown
        const patternDropdown = injectionPoint?.querySelector(
          `.injectionpoints__select[data-line-type="${selectedLineType}"]`
        ) as HTMLSelectElement;

        if (patternDropdown?.options?.length) {
          let valueSelected = false;
          Array.from(patternDropdown.options).forEach(option => {
            if (!option.disabled && !valueSelected) {
              option.selected = true;
              valueSelected = true;
            } else {
              option.selected = false;
            }
          });

          patternDropdown.dispatchEvent(new Event('change'));
        }
      });

      // pattern dropdown listener. Changes selectedLineLocation and shows data accordingly
      patternDropdowns.forEach(patternDropdown => {
        patternDropdown?.addEventListener('change', function () {
          selectedLineLocation = this.value;
          showData();
        });
      });
    };

    // appending listeners to the CTAs (injection points and line patterns)
    const appendCTAListeners = (ctaWrapper: HTMLElement) => {
      const ctaArr = ctaWrapper?.querySelectorAll(
        '.injectionpoints__button'
      ) as NodeListOf<HTMLButtonElement>;

      if (ctaArr?.length) {
        ctaArr.forEach((cta, i) => {
          const type = cta.getAttribute('data-type');
          if (i === 0) {
            selectedCTAType = type;
            cta.classList.add(`injectionpoints__button--active`);
          }

          cta.addEventListener('click', () => {
            ctaArr.forEach((ctaEl, index) => {
              if (index === i) {
                // updating index for animation
                ctaWrapper.style.setProperty('--active-count', '' + index);

                // updating active class to button and updating the selectedCTAType
                ctaEl.classList.add(`injectionpoints__button--active`);
                selectedCTAType = type;
              } else {
                ctaEl.classList.remove(`injectionpoints__button--active`);
              }
            });
            showData();
          });
        });
      }
    };

    // appending listeners to the dropdowns
    appendDropdownListeners();

    // appending listeners to the ctas
    appendCTAListeners(ctaWrapper);

    // triggering the show data for the first time
    linesDropdown.dispatchEvent(new Event('change'));
  };

  const init = () => {
    const injectionPoints = document.querySelectorAll(
      '.injectionpoints'
    ) as NodeListOf<HTMLElement>;

    if (injectionPoints?.length) {
      injectionPoints.forEach(injectionPoint => {
        handleInjectionPoints(injectionPoint);
      });
    }
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
