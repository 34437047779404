(() => {
  const CONSTANTS = {
    mainWrapperClass: 'img-map',
    mainWrapperReadyClass: 'img-map--rendered',
    markerClass: 'emu-mapping-marker',
    contentClass: 'img-map__content',
    contentActiveClass: 'img-map__content--active',
    markerActiveClass: 'emu-mapping-marker--active',
    bodyHasActiveHotspotClass: 'has-active-img-map-hotspot',
  };

  const handleImgMap = imgMap => {
    if (imgMap) {
      imgMap.classList.add(CONSTANTS.mainWrapperReadyClass);
      const markers = imgMap.querySelectorAll(`.${CONSTANTS.markerClass}`);
      const markerMap = {};
      const actualImageMap = imgMap.querySelector('.emu-image-map');
      const body = document.body;

      if (!actualImageMap) {
        return;
      }
      const actualImageMapId = actualImageMap.id;
      let activeKey: string = '';
      let timer;

      // adjusts the position of the content based on position of the marker
      const adjustContentPos = ({ marker, content }) => {
        const markerBounds = marker.getBoundingClientRect();
        const imgMapBounds = imgMap.getBoundingClientRect();

        content.classList.remove(
          `${CONSTANTS.contentClass}--right-top`,
          `${CONSTANTS.contentClass}--left-bottom`,
          `${CONSTANTS.contentClass}--left-top`,
          `${CONSTANTS.contentClass}--right-bottom`
        );

        // marker is in first half of the image
        if (markerBounds.top < imgMapBounds.top + imgMapBounds.height / 2) {
          // marker is in the left half of the image
          if (markerBounds.left < imgMapBounds.left + imgMapBounds.width / 2) {
            content.classList.add(`${CONSTANTS.contentClass}--left-top`);
          } else {
            content.classList.add(`${CONSTANTS.contentClass}--right-top`);
          }
        } else {
          // marker is in the left half of the image
          if (markerBounds.left < imgMapBounds.left + imgMapBounds.width / 2) {
            content.classList.add(`${CONSTANTS.contentClass}--left-bottom`);
          } else {
            content.classList.add(`${CONSTANTS.contentClass}--right-bottom`);
          }
        }
      };

      markers.forEach(marker => {
        const markerLabel = marker.getAttribute('aria-label');
        const content = imgMap.querySelector(
          `.${CONSTANTS.contentClass}--${markerLabel}`
        );

        if (markerLabel) {
          if (content) {
            // setting default position for the content
            content.style.top = marker.style.top;
            content.style.left = marker.style.left;
          }
          const markerObj = {
            content,
            markerLabel,
            marker,
            imgMapId: actualImageMapId,
          };

          markerMap[markerLabel] = markerObj;
          adjustContentPos(markerObj);
        }
      });

      // closing the active hotspot when resized
      let winWidth = window.innerWidth;
      window.addEventListener('resize', () => {
        const curWinWidth = window.innerWidth;
        if (curWinWidth !== winWidth) {
          winWidth = curWinWidth;
          makeMarkerObjInactive();
          Object.values(markerMap).forEach(markerObj => {
            //@ts-ignore
            adjustContentPos(markerObj);
          });
        }
      });

      // makes a marker active, adds appropriate classes to the body, marker and corresponding content
      const makeMarkerObjActive = markerObj => {
        adjustContentPos(markerObj);
        activeKey = markerObj.markerLabel;
        markerObj.content.classList.add(CONSTANTS.contentActiveClass);
        markerObj.marker.classList.add(CONSTANTS.markerActiveClass);
        body.classList.add(CONSTANTS.bodyHasActiveHotspotClass);
        window.Bus.emit('emu-marker-active', markerObj);

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          // listens to the body event to make an active marker inactive when body is clicked
          body.addEventListener(
            'click',
            e => {
              const target = e.target as HTMLElement;
              const closestMarker = target?.closest?.(
                `.${CONSTANTS.markerClass}`
              );
              if (!closestMarker) {
                makeMarkerObjInactive();
              }
            },
            {
              once: true,
            }
          );
        }, 100);
      };

      // makes a marker inactive for a provided marker Object
      // if marker object is not provided, loops through the map, finds the active marker and makes it inactive
      const makeMarkerObjInactive = (markerObj?) => {
        let obj = markerObj;
        if (!obj) {
          Object.keys(markerMap).forEach(markerKey => {
            if (activeKey === markerKey) {
              obj = markerMap[markerKey];
            }
          });
        }

        if (obj?.marker) {
          activeKey = '';
          obj.content.classList.remove(CONSTANTS.contentActiveClass);
          obj.marker.classList.remove(CONSTANTS.markerActiveClass);
          body.classList.remove(CONSTANTS.bodyHasActiveHotspotClass);
          window.Bus.emit('emu-marker-inactive', obj);
        }
      };

      // makes a marker active/inactive
      const handleMapAreaClick = title => {
        const markerObj = markerMap[title];
        if (markerObj) {
          if (activeKey && activeKey === title) {
            makeMarkerObjInactive(markerObj);
          } else {
            if (activeKey) {
              makeMarkerObjInactive();
            }
            makeMarkerObjActive(markerObj);
          }
        }
      };

      window.Bus.on('emu-image-map:areaClick', ({ id, title }) => {
        if (id?.includes?.(actualImageMapId)) {
          handleMapAreaClick(title);
        }
      });
    }
  };

  const init = () => {
    // Wait until the image map becomes available and then start adding logic for managing click events of the buttons in image map
    const imgMapElems = document.querySelectorAll('.img-map');

    if (imgMapElems?.length) {
      // image map markers take time to be populated on the DOM - by AAAEM Common code
      // this listener waits for changes in an element and resolves with a promise when the element is found
      const waitForElm = (selector, parent?) => {
        return new Promise(resolve => {
          const wrapper = parent || document;
          const observeParent = parent || document.body;
          let el = wrapper.querySelector(selector);

          if (el) {
            resolve(el);
            return;
          }

          const observer = new MutationObserver(() => {
            if (wrapper?.querySelector(selector)) {
              resolve(wrapper.querySelector(selector));
              observer.disconnect();
            }
          });

          observer.observe(observeParent, {
            childList: true,
            subtree: true,
          });
        });
      };

      imgMapElems.forEach(imgMap => {
        waitForElm('.emu-mapping-marker', imgMap).then(() => {
          handleImgMap(imgMap);
        });
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
